import React from "react"
import styled from "styled-components"
import { Flex, Box } from "reflexbox/styled-components"
import WrapperContainers from "../../components/WrapperContainer"
import GreenH2 from "../../components/Typography/H2/GreenH2"
import P from "../../components/Typography/P/P"

const Team = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box
        width={[1, 1, 1]}
        p={[3, 2, 3]}
        pr={[0, 0, 2]}
        pt={[3, 3, 5]}
        pb={[3, 3, 5]}
      >
        <GreenH2>Our Team</GreenH2>
        <P>
          Great things in business are never done by one person, they're done by
          a team of people. <br />
          At Advance Manufacturers, we are not just a team; We are also family.
          For us family means a nurturing working culture that provides support,
          flexibility and a healthy work environment. What truly makes our
          company special is undoubtedly our people and we are wholly committed
          to each individuals’ professional growth and career development.{" "}
          <br />
        </P>
        <TeamImg />
      </Box>
      <Box width={[1, 1]} p={[3, 2, 3]}>
        <TeamImg src="https://res.cloudinary.com/denq2jckc/image/upload/v1632644245/About-us/team-3_r3cwkn.jpg" />
      </Box>
      <Box width={[1, 1 / 3, 1 / 3]} p={[3, 2, 3]}>
        <TeamImg src="https://res.cloudinary.com/denq2jckc/image/upload/v1632644534/About-us/team-5_ttzkol.jpg" />
      </Box>
      <Box width={[1, 1 / 3, 1 / 3]} p={[3, 2, 3]}>
        <TeamImg src="https://res.cloudinary.com/denq2jckc/image/upload/v1632644533/About-us/team.6_kzx3iq.jpg" />
      </Box>{" "}
      <Box width={[1, 1 / 3, 1 / 3]} p={[3, 2, 3]}>
        <TeamImg src="https://res.cloudinary.com/denq2jckc/image/upload/v1632644534/About-us/team-4_lwyimc.jpg" />
      </Box>
    </Flex>
  </Section>
)

export default Team
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;

  text-align: center;
`
const TeamImg = styled.img`
  width: 100%;
  height: auto;
`
