import styled from "styled-components"

const GreenH2 = styled.h2`
  font-size: calc(0.8rem + 2vw);
  color: ${({ theme }) => theme.green};
  font-family: "OFL Sorts Mill Goudy";
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-size: calc(1.6em + 0.5vw);
  }
`

export default GreenH2
