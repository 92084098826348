import React from "react"
import styled from "styled-components"
import { Flex, Box } from "reflexbox/styled-components"
import WrapperContainers from "../../components/WrapperContainer"
import GreenH2 from "../../components/Typography/H2/GreenH2"

import P from "../../components/Typography/P/P"

import CeliasH3 from "../../components/Typography/H3/CeliasH3"
const Vision = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box
        width={[1, 1, 1 / 2]}
        p={[3, 2, 3]}
        pr={[0, 0, 2]}
        order={[2, 2, 1]}
        pt={[3, 3, 5]}
      >
        <GreenH2>Our Vision</GreenH2>
        <CeliasH3>
          Thai Craftsmanship, Italian Design &amp; German Precision.
        </CeliasH3>
        <P>
          We employ proven experts from different parts of the world to create a
          meaningful synergy of Thai craftsmanship, Italian design and German
          precision. We believe there is always room for improvement and
          developments to advance our capabilities in an evolving &amp;
          competitive industry when striving for business excellence.
        </P>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 5]} order={[1, 1, 2]}>
        <MotivationImg src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632643897/About-us/team-work-2_omlouh.png" />
      </Box>
    </Flex>
  </Section>
)

export default Vision
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
  background-color: ${({ theme }) => theme.greySection};
`
const MotivationImg = styled.img`
  width: 100%;
  height: auto;
`
