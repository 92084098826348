import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import StyledAboutUsBg from "../containers/AboutUs/Hero/AboutHero"
import Philo from "../containers/AboutUs/Philo"
import Vision from "../containers/AboutUs/Vision"
import Team from "../containers/AboutUs/Team"
import Story from "../containers/AboutUs/Story"

const AboutUs = () => (
  <Layout>
    <Seo title="About Us" />
    <StyledAboutUsBg />
    <Story />
    <Philo />
    <Vision />
    <Team />
  </Layout>
)

export default AboutUs
