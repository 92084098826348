import React from "react"
import { Flex, Box } from "reflexbox"
import styled from "styled-components"

import WrapperContainers from "../../components/WrapperContainer"
import GreenH2 from "../../components/Typography/H2/GreenH2"
import P from "../../components/Typography/P/P"

const Story = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box width={[1, 1]} p={3}>
        <CenterGreen>OUR STORY</CenterGreen>
        <P>
          Advance Manufacturers was founded in 1989 as a joint venture between
          Mr. Vinod Kulisra, a Thai born Indian businessman in the Thai silver
          export business and Friedrich Zettl GmbH, one of the largest jewelry
          manufacturers in Pforzheim, Germany. For over a decade, Advance
          Manufacturers produced gold and silver jewelry exclusively for the
          Zettl Company. In 2003 Mr. Vinod Kulisra purchased the shares of
          Friedrich Zettl GmbH making it a fully Thai owned company and began
          producing jewelry for new customers all over the world. His son Narin
          and daughter Roma soon joined the business and together they have
          dedicated themselves to growing the business while maintaining their
          strong family values which is what Advance Manufacturers stands for.
          Over the last two decades, our capabilities continue to evolve as we
          continue to learn and adapt to new manufacturing technologies. We have
          added high quality brass-based costume jewelry and a stamping
          production line to our manufacturing capability. As of January 2021,
          our production capacity is 3,000,000 pieces of gold, silver and brass
          jewelry per annum.
        </P>
      </Box>
    </Flex>
  </Section>
)

export default Story
const CenterGreen = styled(GreenH2)`
  text-align: center;
`
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`
