import React from "react"
import styled from "styled-components"
import { Flex, Box } from "reflexbox/styled-components"
import WrapperContainers from "../../components/WrapperContainer"
import P from "../../components/Typography/P/P"

import CeliasH3 from "../../components/Typography/H3/CeliasH3"
import GreenH2 from "../../components/Typography/H2/GreenH2"
const Philo = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 5]} order={[2, 2, 1]}>
        <MotivationImg src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632643387/About-us/About-our-philosophy_lb49mk.png" />
      </Box>
      <Box
        width={[1, 1, 1 / 2]}
        p={[3, 2, 3]}
        pr={[0, 0, 2]}
        order={[1, 1, 2]}
        pt={[3, 3, 5]}
      >
        <GreenH2>Our Philosophy</GreenH2>
        <CeliasH3>Innovation Tradition and Integrity</CeliasH3>
        <P>
          At Advance Manufacturers Co., Ltd, we offer high quality jewelry at
          competitive prices while maintaining consistent quality and reliable
          service. We are wholeheartedly committed to pursuing the vision and
          satisfaction of our clients while maintaining long lasting business
          relationships based on trust. It is our belief that the right balance
          of innovation, tradition and integrity is what sets us apart from
          other manufacturers.
        </P>
      </Box>
    </Flex>
  </Section>
)

export default Philo
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`
const MotivationImg = styled.img`
  width: 100%;
  height: auto;
`
